/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'qnLwb3cdmvDAoCN3T5dK6d.png': { uri: '/images/qnLwb3cdmvDAoCN3T5dK6d.png' },
'758QF5SQKxcuWAkh7xX9Au.png': { uri: '/images/758QF5SQKxcuWAkh7xX9Au.png' },
'jieQ43ofVvwh6Eg8GhDwNf.png': { uri: '/images/jieQ43ofVvwh6Eg8GhDwNf.png' },
'uubFXFZehe19UwWnA7bDhi.jpg': { uri: '/images/uubFXFZehe19UwWnA7bDhi.jpg' },
'giinYKUARGiYMSkxXQffdq.png': { uri: '/images/giinYKUARGiYMSkxXQffdq.png' },
'w88xGJk9syP1CBgMkqk1cY.png': { uri: '/images/w88xGJk9syP1CBgMkqk1cY.png' },
'oimk25Cxt3HErKNoALjKnn.png': { uri: '/images/oimk25Cxt3HErKNoALjKnn.png' },
'sfysjDLLDf2uEUyootkTVh.png': { uri: '/images/sfysjDLLDf2uEUyootkTVh.png' },
'qYwjVNdRTDnfsFB1SGocyh.png': { uri: '/images/qYwjVNdRTDnfsFB1SGocyh.png' },
'qdowqmwhf3RL3A5roX5mea.png': { uri: '/images/qdowqmwhf3RL3A5roX5mea.png' }
}

export default imageStaticSourcesByFileName
